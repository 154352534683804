<template>
	<div></div>
</template>

<script setup>
import { onMounted } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { deleteCookie } from "@/common/utils/cookie"
import { useRoute } from "vue-router"

const route = useRoute()

const logout = () => {
	axios.post(api.loginOut).then(() => {
		localStorage.clear()
		deleteCookie(() => {
			window.location.href = decodeURIComponent(route.query.redirect_uri)
		})
	})
}

onMounted(() => {
	logout()
})
</script>

<style></style>
