<template>
	<a-select allowClear :placeholder="selfPlaceholder" style="width: 100%" @change="onChange" :disabled="disabled" v-model:value="countryCode">
		<a-select-option v-for="item in countryList" :value="item.code" :key="item.code">
			{{ item.name }}
		</a-select-option>
	</a-select>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, onMounted } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"

const props = defineProps(["placeholder", "disabled", "code"])
const emits = defineEmits(["update:code", "selected"])

const countryCode = ref()
const countryList = ref([])

onMounted(() => {
	fetchCountryList()
})

const selfPlaceholder = computed(() => {
	return props.placeholder || "请选择国家"
})

const fetchCountryList = async () => {
	let res = await axios.post(api.getCounrtyList)
	countryList.value = res
}
const onChange = (value) => {
	emits("update:code", value)
	if (value) {
		let data = countryList.value.find((item) => item.code === value) || {}
		emits("selected", data)
	} else {
		emits("selected", {})
	}
}
</script>
