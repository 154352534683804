import axios from "axios"
import { message } from "ant-design-vue"
import router from "../router/index"
import JSONBig from "json-bigint"
import { getCookie, deleteCookie } from "@/common/utils/cookie"
// 引入公共函数js文件
// const route = useRouter();
// 默认请求连接
// axios.defaults.baseURL = "https://idaas.shopspade.com/api";
axios.defaults.baseURL = "/"

// 超时时间（ms）
axios.defaults.timeout = 2000 * 1000
// axios请求开启cookie，支持跨域请求携带cookie
axios.defaults.withCredentials = true
// axios 请求头
// axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers["token"] = localStorage.getItem("token") || "";
// axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/jsson"
// axios.defaults.headers.post["dataType"] = "json";
axios.defaults.transformResponse = [
	(data) => {
		return JSONBig.parse(data)
	},
]
// 请求拦截
axios.interceptors.request.use(
	(config) => {
		let token = getCookie("d_session_id"),
			Authorization = getCookie("Authorization")
		config.headers["D-SESSION-ID"] = token
		config.headers["Authorization"] = Authorization
		// 可在这里做一些数据的校验。
		// path
		let path = config.url
		if (path.startsWith("null")) {
			path = path.replace("null", localStorage.getItem("apiURL"))
		}
		config.url = path
		// session的校验等。
		return config
	},
	(error) => {
		return error
	}
)
// 响应拦截
axios.interceptors.response.use(
	(result) => {
		let data = result.data
		// data.code = 7000000;
		// ===========================================================
		// 返回方式一
		// debugger;
		// if (result.status === 200) {
		//   if (result.data && result.data.code > 0) {
		//     return Promise.resolve(result);
		//   } else {
		//     alert(result.data.msg || "操作失败");
		//     return Promise.reject(result);
		//   }
		// } else {
		//   alert("网络异常");
		//   return Promise.reject(result);
		// }

		// ==========================================================
		// 返回方式二
		// 返回数据前做了什么
		// console.log(result);
		// debugger;
		// debugger;
		if (data.code !== 1000000) {
			console.log(data)
			if (data.msg) {
				// 调用自定义alert
				message.error(data.msg)
			}
			if (data.code === 7000000) {
				router.push("/login")
				message.error(data.msg)
				return
				// throw new axios.Cancel("Operation canceled by the user.");
			}
			return Promise.reject(data.result)
		} else {
			return Promise.resolve(data.result)
		}
	},
	(err) => {
		console.log(err)
		let errList = ["Unexpected '<'", "Network Error"]
		if (!err.response && errList.includes(err.message)) {
			message.destroy()
			deleteCookie(() => {
				localStorage.clear()
				message.error("网络异常，请求超时")
				router.push("/login")
			})
			return
		}
		let data = err.response.data
		if (data.code === 7000000) {
			router.push("/login")
			message.error(data.msg)
			return
			// throw new axios.Cancel("Operation canceled by the user.");
		} else {
			message.error(data.msg)
		}
		return Promise.reject("err", data)
	}
)
export default axios
