<template>
	<div class="login-page">
		<div class="login-content">
			<!-- 扑克牌 -->
			<div class="logo-icon">
				<div class="outer2"></div>
				<div class="box1 word1">SRM</div>
			</div>
			<div class="main-content">
				<div class="left-image">
					<img src="../../img/background_main.svg" class="image" />
				</div>
				<div class="login-input-content">
					<div class="login-title">
						<img src="../../img/background_poker.png" alt="" class="image" />
						账号登录
					</div>
					<a-row class="mtp20">
						<a-input id="merchant-name" placeholder="商家名称" v-model:value="merchantName" class="border-show" required />
					</a-row>
					<a-row class="mtp20">
						<a-input v-model:value="userName" placeholder="用户名" class="border-show" required />
					</a-row>
					<a-row class="mtp20">
						<a-input-password
							id="pwd-input"
							placeholder="密码"
							class="border-show"
							:class="{ valid: password }"
							required
							v-model:value="password"
							@keyup.enter="login"
							type="password"
						/>
					</a-row>
					<a-row style="text-align: center; margin: 15px 0; color: red; font-size: 14px" id="error-msg">
						<span v-text="errorMsg"></span>
					</a-row>
					<a-button class="login-btn" type="primary" shape="round" @click="submitForm" :loading="loginLoading"> 登录 </a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits } from "vue"
import sha512 from "js-sha512"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { debounce } from "lodash"

const merchantName = ref("")
const userName = ref("")
const password = ref("")
const emits = defineEmits(["login"])
const loginLoading = ref(false)
const submitForm = debounce(async () => {
	if (loginLoading.value) return
	const data = {
		merchantName: merchantName.value,
		userName: userName.value,
		password: password.value,
	}
	loginLoading.value = true
	data.password = sha512(data.password)
	axios
		.post(api.login, data)
		.then(async (res) => {
			if (res) {
				emits("login", res)
			}
			loginLoading.value = false
		})
		.catch(() => {
			loginLoading.value = false
		})
})
</script>

<style scoped lang="scss">
$primary-color: #1890ff; // 全局主色
$white: #fff;
.login-page {
	background-color: rgba(255, 255, 255, 1);
	position: relative;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding: 30px 0;

	&::before {
		content: url("../../img/background_rt.png");
		position: absolute;
		top: 0;
		right: 0;
	}

	&::after {
		content: url("../../img/background_bottom.png");
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.login-content {
		box-shadow: 10px 15px 17px -5px rgba(0, 0, 0, 0.18);
		background-color: rgba(255, 255, 255, 1);
		border-radius: 50px;
		padding: 0 80px 120px;
		position: relative;
		z-index: 10;

		.logo-icon {
			display: flex;
			align-items: center;
			margin-bottom: 50px;
			margin-top: -30px;

			.outer2 {
				width: 74px;
				height: 89px;
				background: url("../../img/background_poker.png") no-repeat;
				margin-right: 20px;
			}

			.word1 {
				overflow-wrap: break-word;
				color: $primary-color;
				font-size: 36px;
				font-family: PingFangSC-Medium;
				white-space: nowrap;
				margin-top: 10px;
			}
		}

		.main-content {
			display: flex;
			width: 100%;

			.left-image {
				width: 558px;
				// min-width: 262px;
				margin-right: 50px;
				.image {
					display: block;
					width: 100%;
					object-fit: contain;
				}
			}

			.login-btn {
				display: inline-block;
				width: 300px;
				height: 50px;
				font-size: 24px;
			}

			.text-group {
				margin: 25px 0 0 2px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.ding-login-btn {
					margin-left: 20px;
					padding-right: 0;
				}
			}

			.ding-login-content {
				width: 300px;
				font-size: 16px;

				#dingding-login-container {
					min-height: 300px;
				}

				.flex-colunm {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 20px;

					.text-tips {
						margin: 16px 0 30px;
					}
				}
			}
		}
		.mtp20 {
			position: relative;
			margin-top: 20%;
			&::v-deep .ant-input {
				width: 100%;
				font-size: 18px;
				font-family: PingFangSC-Regular;
				border-top: none;
				border-right: none;
				border-left: none;
				outline: none;
				border-radius: 0;
				box-shadow: none;
			}
			.label {
				position: absolute;
				left: 0;
				bottom: 0;
				padding: 10px 0 4px 10px;
				font-size: 1.5em;
				pointer-events: none;
				color: #444;
				transition: 0.3s;
			}
		}
	}

	.login-title {
		width: 118px;
		height: 40px;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 24px;
		font-family: PingFang SC-ExtraLight, PingFang SC;
		text-align: right;
		white-space: nowrap;
		line-height: 40px;
		display: flex;
		font-weight: bold;
		align-items: center;
		.image {
			object-fit: cover;
			width: 28px;
			display: none;
			margin-right: 4px;
		}
	}
}

input:focus {
	outline: 0px solid $white !important;
}

.user-block {
	width: 100%;
	margin-top: 20px;
	height: 50px;
	box-shadow: 0px 0px 10px 0px #e6f4ff;
	border-radius: 10px;
	border: 1px solid #e2e2e2;
	display: flex;
	align-items: center;
	padding-left: 16px;
	cursor: pointer;

	&.active {
		background: rgba(0, 136, 255, 0.1);
		border-color: $primary-color;
	}
}

.ding-select-btn {
	margin-top: 30px;
}

@media only screen and (max-width: 576px) {
	.login-page {
		&::before {
			content: "";
		}
		&::after {
			content: "";
		}
		.login-content {
			box-shadow: none;
			padding: 0;
			width: 100%;
			padding: 0 20px;
			.logo-icon {
				visibility: hidden;
			}
			.main-content {
				.login-title .image {
					display: block;
				}
				.left-image {
					position: fixed;
					top: 32px;
					width: 262px;
					left: 50%;
					transform: translateX(-50%);
				}
				.mtp20 {
					margin-top: 10%;
				}
				.login-input-content {
					width: 100%;
					transform: translateY(-10%);
				}
				.login-btn {
					width: 100%;
					height: 48px;
					font-size: 20px;
				}
				.text-group {
					display: none;
				}
			}
		}
	}
}

.lang-select {
	position: fixed;
	top: 10px;
	right: 10px;
}

.lang-select ::v-deep .icon-global {
	color: $white;
}

.login-page .login-content .mtp20 .valid + span.label,
.login-page .login-content .mtp20 input:focus + span.label,
.login-page .login-content .mtp20 input:valid + span.label {
	transform: translateY(-35px) translateX(-10px);
	font-size: 1.25em;
	background-color: $primary-color;
	color: $white;
	padding: 2px 4px;
	border-radius: 2px;
}
</style>
