export const pathMap = {
	login: "登录",
	loginApp: "首页",
	merchant: "商家管理",
	addMerchant: "商家管理",
	application: "应用管理",
	applicationList: "应用管理",
	addApplication: "添加应用",
	password: "修改密码",
	user: "用户管理",
	addUser: "用户管理",
	organization: "部门和组",
	accountManage: "账户管理",
	appAuth: "应用授权",
	authSystem: "权限系统",
}

export function localGet(key) {
	const value = window.localStorage.getItem(key)
	try {
		return JSON.parse(window.localStorage.getItem(key))
	} catch (error) {
		return value
	}
}

export function localSet(key, value) {
	window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove(key) {
	window.localStorage.removeItem(key)
}
export function bankUp(key) {
	window.localStorage.removeItem(key)
}
