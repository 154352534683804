const state = {
  user: {},
  warehouseList: [],
};

const mutations = {
  setUser(state, val) {
    state.user = val;
  },
  SET_WAREHOUSE(state, val) {
    state.warehouseList = val;
  },
};
const getters = {};

const actions = {
  setWarehouseList(context, state) {
    context.commit("SET_WAREHOUSE", state);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
