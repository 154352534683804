<template>
	<a-modal v-model:visible="visible" :title="'忘记密码'" :ok-text="`发送`" :confirmLoading="loading" @ok="sendResetPasswordMessage">
		<div class="tips fz14 mb10">
			{{ "重置链接会发送到用户邮箱，若没有邮箱请联系管理员维护，若没有邮箱请联系管理员维护" }}
		</div>
		<a-form ref="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
			<a-form-item required :label="'商户名称'">
				<a-input v-model:value="merchantName" />
			</a-form-item>
			<a-form-item required :label="'用户名'">
				<a-input v-model:value="userName" />
			</a-form-item>
			<!-- <a-form-item required :label="('邮箱地址')">
        <a-input v-model:value="resetEmail" />
      </a-form-item> -->
		</a-form>
	</a-modal>
</template>
<script setup>
import { ref, defineProps, defineExpose } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"

const props = defineProps({
	userInfo: Object,
})

const visible = ref(false)
const merchantName = ref(undefined)
const userName = ref(undefined)
// const resetEmail = ref(undefined)
const loading = ref(false)

const open = () => {
	if (props.userInfo) {
		merchantName.value = props.userInfo.merchantName
		userName.value = props.userInfo.userName
	}
	visible.value = true
}

const sendResetPasswordMessage = async () => {
	if (!merchantName.value) {
		return message.warning("请输入商户名称")
	}
	if (!userName.value) {
		return message.warning("请输入用户名")
	}
	// if (!resetEmail.value) {
	//   return message.warning('请输入邮箱')
	// }
	loading.value = true
	try {
		await axios.post(api.sendResetPasswordMessage, {
			appCode: "OMS",
			merchantName: merchantName.value,
			userName: userName.value,
			resetPasswordUrl: location.origin + "/resetPwd",
		})
		loading.value = false
		message.success("发送成功，请注意查收")
		visible.value = false
	} catch (err) {
		loading.value = false
	}
}
defineExpose({
	open,
})
</script>
