import { createRouter, createWebHistory } from "vue-router"
import { getCookie } from "@/common/utils/cookie"
import Login from "../views/Login/index.vue"
import Logout from "../views/logout.vue"
import Page404 from "../views/Page404.vue"
import ResetPwd from "../views/resetPwd.vue"
const MicroWeb = () => import("../views/micro/index.vue")
const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "login",
		component: Login,
	},
	{
		path: "/home",
		name: "home",
		component: () => import(/* webpackChunkName: "home" */ "@/views/Home/Home.vue"),
		children: [
			{
				path: "/overview",
				name: "overview",
				component: () => import(/* webpackChunkName: "Guest" */ "../views/overview/index.vue"),
			},
			{
				path: "/merchantManage",
				name: "merchantManage",
				component: () => import(/* webpackChunkName: "Guest" */ "../views/Merchant/Merchant.vue"),
			},
			{
				path: "/merchantManage/addMerchant",
				name: "addMerchant",
				component: () => import(/* webpackChunkName: "add" */ "../views/Merchant/AddMerchant.vue"),
			},
			{
				path: "/applicationList",
				name: "applicationList",
				component: () => import(/* webpackChunkName:'applicationList' */ "../views/Application/ApplicationList.vue"),
			},
			{
				path: "/addApplication",
				name: "addApplication",
				component: () => import(/* webpackChunkName:'applicationList' */ "../views/Application/AddApplication.vue"),
			},
			{
				path: "/applicationList/saveApplication",
				name: "saveApplication",
				component: () => import(/* webpackChunkName:'saveApplication' */ "../views/Application/SaveApplication.vue"),
			},
			{
				path: "/applicationList/createProduct",
				name: "createProduct",
				component: () => import(/* webpackChunkName:'saveApplication' */ "../views/Application/CreateProduct.vue"),
			},
			{
				path: "/applicationList/AddAppTemplate",
				name: "AddAppTemplate",
				component: () => import(/* webpackChunkName:'saveApplication' */ "../views/Application/AddAppTemplate.vue"),
			},
			{
				path: "/accountManage",
				name: "accountManage",
				component: () => import(/* webpackChunkName:'accountManage' */ "../views/accountManage/accountManage.vue"),
			},
			{
				path: "/accountManage/addAccountManage",
				name: "addAccountManage",
				component: () => import(/* webpackChunkName:'addAccountManage' */ "../views/accountManage/addAccountManage.vue"),
			},
			{
				path: "/accountManage/authAccount",
				name: "authAccount",
				component: () => import(/* webpackChunkName:'addAccountManage' */ "../views/accountManage/authAccount.vue"),
			},
			{
				path: "/organization",
				name: "organization",
				component: () => import(/* webpackChunkName:'organization' */ "../views/organization/organization.vue"),
			},
			{
				path: "/appAuth",
				name: "appAuth",
				component: () => import(/* webpackChunkName:'appAuth' */ "../views/authManage/appAuth/appAuth.vue"),
			},
			{
				path: "/authSystem",
				name: "authSystem",
				component: () => import(/* webpackChunkName:'authSystem' */ "../views/authManage/authSystem/authSystem.vue"),
			},
			{
				path: "/authManage/roleManage",
				name: "roleManage",
				component: () => import(/* webpackChunkName:'authSystem' */ "../views/authManage/roleManage/roleManage.vue"),
			},
			{
				path: "/authManage/oauthDetail",
				name: "oauthDetail",
				component: () => import(/* webpackChunkName:'authSystem' */ "../views/authManage/oauthDetail/oauthDetail.vue"),
			},
			{
				path: "/authManage/resource",
				name: "resource",
				component: () => import(/* webpackChunkName:'authSystem' */ "../views/authManage/resource/resource.vue"),
			},
			{
				path: "/setting",
				name: "setting",
				component: () => import(/* webpackChunkName:'AddApplication' */ "../views/Password/Password.vue"),
			},
			{
				path: "/oauth",
				name: "oauth",
				component: () => import(/* webpackChunkName:'AddApplication' */ "../views/Oauth/Oauth.vue"),
			},
			{
				path: "/oauth/tmsAccount",
				name: "tmsAccount",
				component: () => import("../views/Oauth/tmsAccount.vue"),
			},
			{
				path: "/workorder_web",
				name: "workorder_web",
				component: MicroWeb,
			},
			{
				path: "/workorder_web/:afterUser(.*)",
				name: "workorder_web",
				component: MicroWeb,
			},
			{
				path: "/message/releaseNote",
				name: "releaseNote",
				component: () => import("../views/message/releaseNote"),
			},
		],
	},
	{
		path: "/logout",
		name: "logout",
		component: Logout,
	},
	{
		path: "/Page404",
		name: "Page404",
		component: Page404,
	},
	{
		path: "/resetPwd",
		name: "resetPwd",
		component: ResetPwd,
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/Page404",
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach((to, from, next) => {
	// console.log("to: ", to)
	// 手动修改history的state
	if (to.name === "login" && !to.query.code) {
		if (!getCookie("Authorization")) {
			next()
		} else {
			next({ name: "overview" })
		}
	}
	next()
})

export default router
