<template>
	<div class="reset-pwd">
		<div class="form-content">
			<div class="title">重置密码</div>
			<a-form style="margin: 0 auto" :model="ruleForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="submitForm">
				<a-form-item has-feedback label="新密码" name="newpass">
					<a-input-password v-model:value="ruleForm.newpass" autocomplete="off" />
				</a-form-item>
				<a-form-item has-feedback label="确认密码" name="newpass2">
					<a-input-password v-model:value="ruleForm.newpass2" autocomplete="off" />
				</a-form-item>
				<a-form-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
					<div class="flex">
						<a-button type="primary" html-type="submit"> 确定 </a-button>
					</div>
				</a-form-item>
			</a-form>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import { message } from "ant-design-vue"
import sha512 from "js-sha512"
export default {
	name: "ResetPwd",

	setup() {
		const store = useStore()
		const route = useRoute()
		let validatePass = async (_rule, value) => {
			let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@_#.$%^&*])[A-Za-z\d~!@_#.$%^&*]{8,20}$/
			if (value === "") {
				return Promise.reject("密码不能为空，请重新输入")
			} else if (!reg.test(value)) {
				return Promise.reject("密码长度应为8-20位，并且必须包含大写和小写字母、数字、特殊符号")
			} else {
				return Promise.resolve()
			}
		}

		let validatePass2 = async (_rule, value) => {
			if (value === "") {
				return Promise.reject("密码不能为空，请重新输入")
			} else if (value !== state.ruleForm.newpass) {
				return Promise.reject("两次密码不一样")
			} else {
				return Promise.resolve()
			}
		}

		const state = reactive({
			user: null,
			ruleForm: {
				newpass: "",
				newpass2: "",
			},
			rules: {
				newpass: [
					{
						validator: validatePass,
						trigger: "blur",
					},
				],
				newpass2: [
					{
						validator: validatePass2,
						trigger: "blur",
					},
				],
			},
		})

		const submitForm = () => {
			let data = {
				merchantId: store.getters["user/merchantId"],
				password: sha512(state.ruleForm.newpass),
				resetPasswordToken: route.query.resetPasswordToken,
			}

			axios.post(api.resetPassword, data).then(() => {
				message.success("密码重置成功", 1.2).then(() => {
					window.location.href = route.query.redirectUrl
				})
			})
		}

		return {
			...toRefs(state),
			labelCol: { span: 6 },
			wrapperCol: { span: 18 },
			submitForm,
		}
	},
}
</script>

<style lang="scss" scoped>
.reset-pwd {
	width: 100%;
	height: 100vh;
	background-color: #090723;
	overflow: hidden;
	.title {
		font-size: 18px;
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		font-weight: bold;
	}
	.form-content {
		width: 520px;
		height: auto;
		border: 1px solid grey;
		padding: 30px 30px 0;
		margin: 100px auto;
		background-color: white;
		border-radius: 4px;
	}
	.flex {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
