<template>
  <div class="layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  background-color: #ffffff;
}
</style>
