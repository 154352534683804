<template>
  <div>
    <h1>当前页面不存在，404.......，<a @click="Backup">点击返回</a></h1>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "404",
  setup() {
    const router = useRouter();
    const Backup = () => {
      router.go(-2);
    };
    return {
      Backup,
    };
  },
};
</script>

<style></style>
