import { workorderWebEntry } from "../config/config.js"
import actions from "./actions"
// 作为管理应用的文件，需要导出在main.js挂载
const microApps = [
    /**
     * name: 微应用名称 - 具有唯一性
     * entry: 微应用入口 - 通过该地址加载微应用
     * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
     * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
     */
    //工单管理
    {
        name: "workorder_web",
        entry: workorderWebEntry,
        container: "#subapp-viewport",
        activeRule: "/workorder_web",
    },
]

const apps = microApps.map((item) => {
    return {
        ...item,
        props: {
            routerBase: item.activeRule,
            getGlobalState: actions.getGlobalState,
        },
    }
})

export default apps
