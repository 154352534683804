import {
	DatePicker,
	Button,
	Form,
	Input,
	Menu,
	Table,
	Modal,
	Descriptions,
	Select,
	Pagination,
	Drawer,
	Switch,
	Card,
	Upload,
	Alert,
	Avatar,
	Layout,
	Popover,
	Divider,
	Popconfirm,
	Row,
	Badge,
	Tree,
	Col,
	Radio,
	List,
	Tabs,
	Tag,
	Transfer,
	AutoComplete,
	Tooltip,
	Spin,
	Cascader,
	Checkbox,
	TreeSelect,
	Dropdown,
	Progress,
} from "ant-design-vue"

export const antdComponents = [
	DatePicker,
	Button,
	Form,
	Input,
	Menu,
	Table,
	Modal,
	Descriptions,
	Select,
	Pagination,
	Drawer,
	Switch,
	Card,
	Upload,
	Alert,
	Avatar,
	Layout,
	Popover,
	Divider,
	Popconfirm,
	Row,
	Badge,
	Tree,
	Col,
	Radio,
	List,
	Tabs,
	Tag,
	Transfer,
	AutoComplete,
	Tooltip,
	Spin,
	Cascader,
	Checkbox,
	TreeSelect,
	Dropdown,
	Progress,
]
